import { navigate } from 'gatsby'
import Seo from 'src/components/seo/Seo'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

const isBrowser = typeof window !== `undefined`

function Page() {
  const { loginUrl } = useStoreConfig()

  if (!isBrowser) {
    return null
  }

  if (document.cookie.indexOf('memberAuthToken') < 0) {
    window.location.href = loginUrl

    return null
  }

  navigate('/')

  return (
    <>
      <Seo />

      <div>loading...</div>
    </>
  )
}

export default Page
